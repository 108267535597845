<template>
  <div>
    <SButtonCreateOrder class="mb-3" to="retail/new" />
    <OrderList
      store="order.order_retail"
      resource="/sale/orders/retail"
      orderStatusStore="order.retail_statuses"
    />
  </div>
</template>

<script>
import OrderList from "../components/OrderList.vue";

export default {
  components: { OrderList },

};
</script>
